
// import { Footer } from '@material-ui/core';

// import { Footer } from 'react-bootstrap'



const Footer = () =>  {

        return (
            <footer className="footer mt-auto py-3 bg-light">
                <div className="container">
                    <span className="text-muted">Copyright © 2021 Zahnzentrumdrhijazi | Powered by Zahnzentrumdrhijazi</span>
                </div>
            </footer>
        )
}

export default Footer;